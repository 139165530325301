import React, { Component } from 'react';
import { BrowserRouter as Router, Route, NavLink } from "react-router-dom";
import { NetworkMapPage } from './NetworkMap';
import AccessMaps from './AccessMaps';
import RidershipTables from './RidershipTables';
import RidershipMaps from './RidershipMaps';
import Background from './Background';

class App extends Component {
  render() {
    return (
      <Router>
        <React.Fragment>
          <nav className="navbar is-fixed-top is-link">
            <div className="navbar-brand">
              <img src="/seamless-logo-white.png" alt="Seamless Bay Area"/>
            </div>
            <div className="navbar-menu">
              <div className="navbar-start">
                <NavLink to="/" className="navbar-item" activeClassName="is-active">Vision Map</NavLink>
                <NavLink to="/access-maps" className="navbar-item" activeClassName="is-active">Accessibility Analysis</NavLink>
                {/* <NavLink to="/ridership-maps" className="navbar-item" activeClassName="is-active">Ridership Maps</NavLink> */}
                {/* <NavLink to="/ridership-tables" className="navbar-item" activeClassName="is-active">Driving & Transit Ridership Analysis</NavLink> */}
                <NavLink to="/background" className="navbar-item" activeClassName="is-active">Background Info</NavLink>
              </div>
            </div>
          </nav>
          <section className="hero is-fullheight-with-navbar">
            {/* <div className="hero-body"> */}
              {/* <div className="container"> */}
                <Route exact path="/" component={NetworkMapPage} />
                <Route path="/access-maps" component={AccessMaps} />
                <Route path="/ridership-maps" component={RidershipMaps} />
                <Route path="/ridership-tables" component={RidershipTables} />
                <Route exact path="/background" component={Background} />
              {/* </div> */}
            {/* </div> */}
          </section>
        </React.Fragment>
      </Router>
    );
  }
}

export default App;
