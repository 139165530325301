export const countyLabels = ['Alameda (Outer)', 'Alameda (Inner)', 'Contra Costa (Outer)', 'Contra Costa (Inner)', 'Marin', 'Napa', 'San Francisco', 'San Mateo', 'Santa Clara', 'Solano', 'Sonoma']

// comes from Table 8.01
export const countyToCountyPmt = [
  [-891,-22091,219,514,0,0,-18296,-3840,-3390,211,0],
  [-351,-52330,3919,5700,-1736,-2,-2678,-24131,-92058,-912,-167],
  [70,4702,-10921,9230,-790,-40,33776,5469,-642,-7863,-3],
  [345,-8521,1966,-6403,-6387,0,-962,2367,-1480,-286,-24],
  [0,-2319,-2,-461,-34122,0,-220356,-505,-854,-2,-136],
  [0,-60,-484,-2,-2,-5453,-1940,0,-1,-3144,0],
  [402,1392,1034,-277,-5393,0,-62031,-9283,-9333,220,-493],
  [-294,-3481,691,-350,-1259,0,-9235,-5097,-12941,120,0],
  [159,-92213,-21,-7441,-471,0,-5783,-20914,-687540,0,-4],
  [8,-1073,-1215,398,-9269,-4510,34002,699,-241,-9178,-166],
  [-1,-3161,-25,-42,-3924,0,-126320,-1554,-333,-74,-7080]
]
export const meanRidersPerCar = 1.7;
export const countyToCountyVmt = countyToCountyPmt.map((row) => row.map((cell) => cell / meanRidersPerCar ))

// comes from mode_share_calculations.xls
export const transitModeShare2015 = [
  [0.019976636,0.165867689,0.003512469,0.073412698,0,null,0.349591204,0.017518248,0.057088184,0.025974026,0],
  [0.075289009,0.283564905,0.055932459,0.410930693,0.024126456,0,1.069869414,0.078107973,0.069736737,0.103142627,0],
  [0.011134904,0.22641796,0.031995356,0.25,0,0,0.790571284,0.079824857,0.007738441,0.011596548,0],
  [0.040658277,0.442672656,0.054584374,0.177439262,0.153657683,0,1.03964213,0.231374924,0.057692308,0.064814815,0],
  [0,0.133619268,0.003676471,0.052014278,0.043200269,0,0.484964108,0.029874214,0.025793651,0,0.040723982],
  [0,0,0,0,0,0,0,0,0,0,0],
  [0.340277778,0.514021393,0.16350365,0.421161826,0.017183414,0,0.601857063,0.216318498,0.190061719,0.45,0],
  [0.027472527,0.242134919,0.117845118,0.238938053,0.004766444,0,0.953380009,0.086807614,0.172464299,0.019354839,0],
  [0.041698256,0.074977596,0.007246377,0.044526902,0.005555556,0,0.820142536,0.119721215,0.096473311,0,0],
  [0,0.043799543,0.007240204,0.012290998,0.002920561,0,0.553924598,0.020257827,0.010733453,0.022430073,0],
  [0,0,0,0,0.108148988,0,0,0,0,0,0.031464857]
]
export const transitModeShare2040NoBuild = [
  [0.017816686,0.159443028,0.001871177,0.078101072,0,null,0.353889736,0.010356732,0.052914015,0.036809816,0],
  [0.085103885,0.29046682,0.054925837,0.397767607,0.026583268,0,1.057152299,0.068715922,0.064721969,0.090671885,0],
  [0.013007489,0.212202315,0.027523995,0.246202455,0,0,0.783585817,0.066894835,0.005907173,0.012483574,0],
  [0.046530612,0.486642736,0.048599473,0.172347558,0.1332797,0,1.036923077,0.216764308,0.043701799,0.080679406,0],
  [0,0.138294138,0,0.061468647,0.042788613,0,0.471038074,0.022498061,0.021226415,0,0.038038884],
  [0,0,0,0,0,0,0,0,0,0,0],
  [0.324909747,0.515446624,0.170588235,0.422391858,0.014820304,0,0.598649319,0.199038668,0.178879006,0.45,0],
  [0.027777778,0.232474581,0.117056856,0.25415677,0.00481232,0,0.93810785,0.089058925,0.166949076,0.016172507,0],
  [0.059249506,0.07345301,0.003393665,0.049475262,0.004807692,0,0.871797913,0.129329841,0.102459547,0,0],
  [0,0.037612769,0.006160703,0.010006801,0.003852504,0,0.511913154,0.015735641,0.006528836,0.020848337,0],
  [0,0,0,0,0.118199675,0,0,0,0,0,0.034110328]
]
export const transitModeShare2040Build = [
  [0.018777088,0.198666405,0.000935588,0.053598775,0,null,0.456090652,0.057920982,0.06651691,0,0],
  [0.085397347,0.30389449,0.04320228,0.394918737,0.079749805,0,1.060376943,0.104764399,0.136642715,0.111545988,0.009966777],
  [0.012087768,0.202983669,0.03100893,0.196743225,0.018518519,0.001472754,0.717163309,0.026446795,0.006919831,0.100525624,0],
  [0.038367347,0.502153788,0.037107972,0.185780824,0.234915527,0,1.036430769,0.181613339,0.11311054,0.090587403,0],
  [0,0.174096174,0.004329004,0.071369637,0.074683761,0,0.821867882,0.032583398,0.061320755,0,0.041420118],
  [0,0.011111111,0.058823529,0,0,0.010882882,0.157088123,0,0,0.174111212,0],
  [0.288808664,0.510134663,0.119117647,0.440203562,0.143386439,0,0.628971776,0.208618952,0.209515742,0.275,0.042253521],
  [0.083333333,0.247687486,0.053511706,0.213776722,0.03946102,0,0.955073376,0.090161955,0.188386941,0.008086253,0],
  [0.055299539,0.20556174,0.003393665,0.233883058,0.021634615,0,0.865512334,0.15295381,0.143250456,0,0],
  [0,0.040666204,0.01612269,0.007966579,0.126582278,0.02055993,0.341745383,0.003147128,0.009793254,0.031645941,0.008163265],
  [0,0.051260504,0.002227171,0.004807692,0.130497908,0,0.530254777,0.074074074,0.004968944,0.001629992,0.037707728],
]

export const matrixWithTotalColumnAndTotalRow = function(matrix) {
  let matrixWithTotalColumn = matrix.map((row) => {
    let total = row.reduce((a, b) => a + b, 0)
    row.push(total)
    return row
  })
  let newRow = matrix.reduce((r, a) => r.map((b, i) => a[i] + b))
  let matrixWithTotals = [...matrixWithTotalColumn, newRow]
  return matrixWithTotals
}