import React, { Component } from 'react';

class Background extends Component {
  render() {
    return (
      <section className="container is-fluid">
        <div className="level" style={{ paddingTop: '2em' }}>
          <img src="seamless-logo.png" alt="Seamless Bay Area" />
          <img src="interline-logo.png" alt="Interline Technologies" />
        </div>
        <h2 className="title is-2">Overview</h2>
        <p>How would a fast, reliable and seamlessly integrated public transit system improve life in the San Francisco Bay Area?  To answer this question, Interline has worked with <a href="http://www.seamlessbayarea.org">Seamless Bay Area</a> to model its <a href="https://www.seamlessbayarea.org/vision-map">Vision Map</a> to understand how implementation of this vision would make it faster and easier to get around the Bay Area on transit.  <a href="https://www.seamlessbayarea.org/background-vision-map">Read more</a> on how the Seamless Bay Area Vision Map was created.  This work has been generously funded by a grant from Microsoft.</p>
        <h2 className="title is-2">Vision Map Service Levels</h2>
        <p>Because there are no service standards for transit in the Bay Area, Seamless Bay Area and Interline worked together to standardize the definition all of the various types of transit in the region into a few simple categories. Only rapid and regional lines are shown on the Vision Map; local and/or less frequent transit lines would still exist but are not displayed.</p>
        <img src="/service-level-legend.png" alt="a table showing the names, frequencies, and speeds of each level of transit service used in the Seamless network" style={{maxWidth: '500px'}}/>
        <h2 className="title is-2">Accessibility Analysis</h2>
        <p>The goal of this analysis is to determine where people can reasonably travel by transit. Accessibility analysis can be performed from an origin point; for example, from your house. Or it can be performed to a destination point; for example, to your office. When performing accessibility analysis from an origin, we calculate all of the area that can be reached from your house within 60 minutes (or some other defined amount of times).</p>
        <p>The result of an accessibility analysis is called an isochrone. That's the combination of "iso" for same (in Greek) and "chrone" for time. It's the area reachable in the same amount of time. An isochrone can also be called a <em>travelshed</em>. Like a watershed, an isochrone/travelshed can have jagged edges. Unlike a watershed, isochrones are not necessarily contiguous. Train stations and ferry terminals can mean that riders can access destinations around the stops, but not necessarily all along the route line. Therefore, transit isochrones can look like islands.</p>
        <p>Often isochrones are combined with other geographic data. In the accessibility analysis of the Seamless Vision Map, we've used residential and workplace demographics. After caluclating an isochrone to or from a given destination, our analysis software counts the number of residences or the number of workplaces within each isochrone.</p>
        <a className="button is-link is-outlined is-pulled-right" href="/access-maps">Explore the Accessibility Analysis</a>
        <h2 className="title is-2">Related Resources</h2>
        <p>For more information on transit planning and accessibility analysis:</p>
        <div class="content">
          <ul>
            <li><a href="https://www.atlasobscura.com/articles/isochrone-maps-commutes-travel-times">Why Isochrone Maps Are Enjoying a Renaissance: As data sources keep improving, mapmakers keep finding more uses for the 19th-century invention.</a> (Atlas Obscura)</li>
            <li><a href="https://blog.conveyal.com/shaping-conversations-about-transit-with-interactive-isochrone-mapping-1d6530d6a8a4">Shaping Conversations about Transit with Interactive Isochrone Mapping</a> (Conveyal)</li>
            <li><a href="https://www.vtpi.org/access.pdf">Evaluating Accessibility for Transport Planning: Measuring People's Ability to Reach Desired Goods and Activities</a> (Victoria Transport Policy Institute)</li>
          </ul>
        </div>
      </section>
    )
  }
}

export default Background;
