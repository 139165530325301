import React, { Component } from 'react';
import * as d3 from 'd3';
import * as d3Chromatic from 'd3-scale-chromatic';
import HeatMap from 'react-heatmap-grid';
import { countyLabels, countyToCountyVmt, matrixWithTotalColumnAndTotalRow } from './RidershipData';

const countyPmtScale = d3.scaleThreshold().domain([-687540, -500000, -100000, -1000, -5, 5, 1000, 100000, 500000, 687540]).range(d3Chromatic.schemeRdBu[11].reverse())
const ridershipPercentScale = d3Chromatic.schemeBlues[4]

class RidershipTables extends Component {
  render() {
    return (
      <div className="container is-fluid" style={{paddingTop: ".5em"}}>
        <h4 className="title is-4">Transit Mode Share</h4>
        <p>Seamless Bay Area’s Vision Map was modeled using the Federal Transit Administration's Simplified Trips-on-Project Software (STOPS) to estimate how it would impact vehicle miles traveled and transit ridership.  <a href="/background">See more information</a>.</p>
        <p>The model suggests that the Seamless Network could attract many more transit riders &mdash; if built by 2040, the model projects approximately <span style={{fontSize: '2em', color: ridershipPercentScale[3]}}>22.5%</span> of all weekday trips would be on transit. Today, according to MTC, <a href="http://2040.planbayarea.org/sites/default/files/2017-07/Travel_Modeling_PBA2040_Supplemental%20Report_7-2017_0.pdf">approximately 6% of weekday trips are on transit</a>.</p>
        <h4 className="title is-4">Vehicle Miles Traveled (VMT)</h4>
        <p>The model suggests that implementing the Seamless Vision would result in less driving, as measured by the amount of Vehicle Miles Traveled (VMT) in the region. The model estimates an overall reduction of 900,850 fewer miles driven each day.  Because VMT is closely correlated to greenhouse gas emissions (GHG), reductions of VMT would very likely lead to reductions in GHG emissions.</p>
        <p>Estimated change in daily intra-regional vehicle-miles traveled (VMT) associated with the full Seamless network expansion relative to a "no-expansion" scenario for a typical weekday in the year 2040. VMT is calculated from vehicle-based person-miles traveled (PMT) using the 2018 national average vehicle occupancy factor of 1.7 passengers per vehicle.</p>
        <p><strong>Scale</strong>: <span style={{fontWeight: 'bold', color: ridershipPercentScale[3]}}>Blue negative values</span> in the table represent a decrease in miles driven, attributable to the expansion of the transit network. <span style={{fontWeight: 'bold', color: countyPmtScale(10000)}}>Red positive values</span> represent an increate in miles driven.</p>
        <HeatMap
          xLabels={[...countyLabels, 'TOTALS']}
          yLabels={[...countyLabels, 'TOTALS']}
          yLabelWidth={120}
          data={matrixWithTotalColumnAndTotalRow(countyToCountyVmt)}
          cellRender={value => value && d3.format(',d')(value)}
          cellStyle={(background, value, min, max, data, x, y) => {
            return {
              background: (x === 11 && y === 11) ?  '#fff' : countyPmtScale(value),
              boxShadow: (x === 11 && y === 11) ? `inset 0 0 0 3px ${countyPmtScale(value)}` : '',
              fontSize: "11px",
              fontWeight: (x === 11 || y === 11) ? 'bold' : 'normal'
            }
          }}
        />
      </div>
    )
  }
}

export default RidershipTables;
