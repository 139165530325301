import React, { Component } from 'react';
import { LayersControl, GeoJSON } from 'react-leaflet';
import L from 'leaflet';
import LControl from 'react-leaflet-control';
import { format } from 'd3';

import NetworkMap from './NetworkMap'

import pmtGeojson from './scenario-geojson/dec19/pmt/pmtcounties.json';
import { countyLabels, meanRidersPerCar } from './RidershipData';

L.Icon.Default.imagePath = "/"

class RidershipMaps extends Component {
  extraLayers = {}

  render() {
    const pmtControls = (
      <LayersControl.Overlay key="vmt" name="Vehicle Miles Traveled (VMT) by Private Auto" checked={true}>
      <GeoJSON
        key="vmt"
        data={pmtGeojson}
        style={(feature) => { return {
          color: (feature.geometry.type === 'LineString' ? feature.properties.stroke : '#000'),
          weight: (feature.geometry.type === 'LineString' ? feature.properties['stroke-width']/2.0 : 2),
          fillColor: '#ccc'
        }}}
        pointToLayer={(feature, latlng) => {
          return L.circleMarker(latlng, {
              radius : 5,
              fillColor : '#fff',
              color : '#000',
              weight : 2,
              opacity : 1,
              fillOpacity : 0.5
          })
        }}
        onEachFeature={(feature, layer) => {
          L.setOptions(layer, {riseOnHover: true}); // TODO: check if this works
          if (!feature.properties) { return}
          if (feature.geometry.type === 'Point' || feature.geometry.type === 'Polygon' || feature.geometry.type === 'MultiPolygon') {
            layer.bindTooltip(`<h5>${countyLabels[feature.properties.district - 1]}</h5>`);
          } else if (feature.geometry.type === 'LineString') {
            let origin = countyLabels[feature.properties.origin.match(/([1-9][0-9]*)/)[0] - 1];
            let destination = countyLabels[feature.properties.dest.match(/([1-9][0-9]*)/)[0] - 1];
            layer.bindTooltip(`
              <h5>County-to-County VMT</h5>
              Origin: ${origin}<br />
              Destination: ${destination}<br />
              VMT 2040 Build vs. No-Build: ${format(',d')(feature.properties.value / meanRidersPerCar)}
            `);
          }
        }}
      />
      </LayersControl.Overlay>
    )

    return (
      <React.Fragment>
        <NetworkMap extraLayers={this.extraLayers}>
          <LControl position="topleft" >
            <article className="message is-warning">
              <div className="message-header">
                <h3 className="title is-3">Ridership Maps</h3>
              </div>
              <div className="message-body">
                <p>These maps display results from the ridership projections. The <em>Vehicle Miles Traveled (VMT) by Private Auto</em> layer shows the change in auto traffic flows between "counties." (We've split Contra Costa and Alameda in to inner and outer halves.)</p>
                <p><span style={{color: 'green'}}>Green flow lines</span> indicate a decrease in VMT between 2015 and 2040, thanks to implementation of the full Seamless network. <span style={{color: 'red'}}>Red flow lines</span> indicate an increase in VMT.</p>
                <p><span style={{fontWeight: 1000}}>Wider flow lines</span> indicate a bigger magnitude of change. <span style={{fontWeight: 200}}>Narrower flow lines</span> indicate a smaller amount of change.</p>
                <p>Mouse over each flow line to view more information.</p>
                <p>Or download this map as an <a href="/scenario-geojson/dec19/pmt/pmtcounties.svg">SVG file</a>.</p>
              </div>
            </article>
          </LControl>
          {pmtControls}
        </NetworkMap>
      </React.Fragment>
    )
  }
}

export default RidershipMaps;
