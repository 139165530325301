import React, { Component } from 'react';
import { Map, ZoomControl, AttributionControl, TileLayer, LayersControl, GeoJSON } from 'react-leaflet';
import L from 'leaflet';
import LControl from 'react-leaflet-control';

import metroGeojson from './scenario-geojson/layer-metro.json';
import regionalRailGeojson from './scenario-geojson/layer-regionalrail.json';
import commuterRailGeojson from './scenario-geojson/layer-commuterrail.json';
import regionalBusGeojson from './scenario-geojson/layer-regionalbus.json';
import ferryGeojson from './scenario-geojson/layer-ferry.json';
import localBusGeojson from './scenario-geojson/layer-bus.json';

L.Icon.Default.imagePath = "/"

class BaseNetworkMap extends Component {
  mapState = {
    lat: 37.7,
    lng: -122.42, 
    zoom: 10,
  }

  layers = {
    metro: {
      displayName: '<span style="color: #D91A5B; font-weight: bold;">Metro</span>',
      geojson: metroGeojson,
      color: '#D91A5B',
      weight: 4
    },
    regionalRail: {
      displayName: '<span style="color: #8BC63E; font-weight: bold;">Regional Express Rail</span>',
      geojson: regionalRailGeojson,
      color: '#8BC63E',
      weight: 4
    },
    regionalBus: {
      displayName: '<span style="color: #8BC63E; font-weight: light;">Regional Rapid Bus</span>',
      geojson: regionalBusGeojson,
      color: '#8BC63E',
      weight: 2
    },
    localBus: {
      displayName: '<span style="color: #F7941F;">Street-level rapid transit</span>',
      geojson: localBusGeojson,
      color: '#F7941F',
      weight: 2,
      hideMarkers: true
    },
    commuterRail: {
      displayName: '<span style="color: #666">Commuter Rail</span>',
      geojson: commuterRailGeojson,
      color: '#666',
      weight: 2
    },    
    ferry: {
      displayName: '<span style="color: #666;">Commuter Ferry</span>',
      geojson: ferryGeojson,
      color: '#999',
      weight: 1,
      dashArray: '4, 4'
    }
  }

  constructor(props) {
    super(props);
    this.mapRef = React.createRef();
    this.layers = Object.assign(this.layers, props.extraLayers);
  }

  render() {
    const position = [this.mapState.lat, this.mapState.lng]
    const overlayLayerControls = Object.keys(this.layers).map((layerKey) => {
      return (
        <LayersControl.Overlay
          key={layerKey}
          name={this.layers[layerKey].displayName}
          checked={this.props.showNetworkLayersByDefault}>
          <GeoJSON
            key={layerKey}
            data={this.layers[layerKey].geojson}
            riseOnHover={true}
            style={(feature) => { return {
              color: this.layers[layerKey].color,
              weight: this.layers[layerKey].weight,
              opacity: (feature.geometry.type === 'Polygon' ? 0.1 : 0.9),
              dashArray: this.layers[layerKey].dashArray,
            }}}
            pointToLayer={(feature, latlng) => {
              if (this.layers[layerKey].hideMarkers) { return null }
              return L.circleMarker(latlng, {
                  radius : this.layers[layerKey].weight,
                  fillColor : '#fff',
                  color : this.layers[layerKey].color,
                  weight : this.layers[layerKey].weight,
                  opacity : 1,
                  fillOpacity : 0.5
              })
            }}
            />
        </LayersControl.Overlay>
      )
    })

    return (
      <React.Fragment>
        <Map center={position} zoom={this.mapState.zoom} zoomControl={false} attributionControl={false} id="map" attribution='' ref={this.mapRef}>
          <ZoomControl position='bottomleft'/>
          <AttributionControl prefix='Interline Technologies' />
          <LayersControl position="bottomright" collapsed={false}>   
          <LayersControl.Overlay name="OpenStreetMap road network" checked={true}>
              <TileLayer
                attribution='&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>, &copy; <a href="https://carto.com/attributions">CARTO</a>'
                url="https://{s}.basemaps.cartocdn.com/rastertiles/light_nolabels/{z}/{x}/{y}.png"
              />
            </LayersControl.Overlay>
            <LayersControl.Overlay name="OpenStreetMap city labels" checked={true}>
              <TileLayer
                attribution='&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>, &copy; <a href="https://carto.com/attributions">CARTO</a>'
                url="https://{s}.basemaps.cartocdn.com/rastertiles/light_only_labels/{z}/{x}/{y}.png"
                zIndex={1000}
              />
            </LayersControl.Overlay>
            {overlayLayerControls}
            {this.props.children}
          </LayersControl>
        </Map>
      </React.Fragment>
    )
  }
}

class NetworkMapPage extends Component {
  render() {
    return (
      <BaseNetworkMap showNetworkLayersByDefault={true}>
        <LControl position="topleft" >
          <article className={"message is-warning " + (this.state && this.state.hideMessage ? 'is-hidden': '')}>
            <div className="message-header">
              <h3 className="title is-3">Vision Map</h3>
              <button class="delete" onClick={() => this.setState({hideMessage: true})}></button>
            </div>
            <div className="message-body">
              <p>This map shows how Seamless Bay Area’s Vision Map of an integrated high frequency network could operate throughout the region.</p>
              <p>Each color on the map corresponds to a different type of transit service. See <a href="/background">Background Info</a> for the speed and frequency of each service type.</p>
              <p>Only rapid and regional lines are shown on this map; local and/or less frequent transit lines would still operate but are not displayed for clarity.</p>
              <p>The <a href="/access-maps">Accessibility Analysis</a> estimates how implementation of the Vision Map would change access to housing and jobs throughout the region.</p>
            </div>
          </article>
        </LControl>
      </BaseNetworkMap>
    )
  }
}

export { BaseNetworkMap as default, NetworkMapPage };