import React, { Component } from 'react';
import ReactDOMServer from 'react-dom/server';
import { Marker, Popup, LayersControl, GeoJSON, LayerGroup } from 'react-leaflet';
import L from 'leaflet';
import LControl from 'react-leaflet-control';
import { format } from 'd3';
import {
  BarChart, Bar, XAxis, YAxis, CartesianGrid
} from 'recharts';
import centroid from '@turf/centroid';

import NetworkMap from './NetworkMap'

import cocGeojson from './scenario-geojson/coc.json';
import scenarios from './scenario-geojson/jobs-housing-origins.json';

L.Icon.Default.imagePath = "/"

const isochroneStyleFunction = (feature) => { return {
  color: feature.properties.fill,
  weight: 2,
  opacity: 1.0
}}

let originPopupContents = (results) => {
  let chartData = [
    {
      type: 'Residents',
      'Existing network in 2015': results.Existing['2015_TOTPO'],
      'Seamless network in 2040': results.Seamless['2040_TOTPO']
    },
    {
      type: 'Jobs',
      'Existing network in 2015': results.Existing['2015_TOTEM'],
      'Seamless network in 2040': results.Seamless['2040_TOTEM']
    }
  ];
  console.log(chartData)
  return (
    <>
      <BarChart
        width={300}
        height={250}
        data={chartData}
        margin={{
          top: 20, right: 30, left: 20, bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="type" />
        <YAxis tickFormatter={(value) => format(',')(value)} />
        <Bar dataKey="Existing network in 2015" fill="#D4AF7F" isAnimationActive={false} />
        <Bar dataKey="Seamless network in 2040" fill="#99d6ff" isAnimationActive={false} />
      </BarChart>
      <p><strong>In the chart</strong>: Using the <span style={{color: '#D4AF7F', fontWeight: '800'}}>current network</span> {format(',')(results.Existing['2015_TOTPO'])} residents and {format(',')(results.Existing['2015_TOTEM'])} jobs are within a 60 minute transit trip of this location. Using the <span style={{color: '#99d6ff', fontWeight: '800'}}>Seamless network</span> in the year 2040, {format(',')(results.Seamless['2040_TOTPO'])} residents and {format(',')(results.Seamless['2040_TOTEM'])} jobs will be within a 60 minute transit trip of this location.</p>
      <p><strong>On the map</strong>: <span style={{color: '#D4AF7F', fontWeight: '800'}}>Brown polygon</span> highlights area accessible within 60 minutes by transit currently. <span style={{color: '#99d6ff', fontWeight: '800'}}>Blue polygon</span> highlights area accessible within 60 minutes by transit using the Seamless network.</p>
      {/* TODO: check for any locations with 90 minute access */}
    </>
  )
}

class AccessMaps extends Component {
  extraLayers = {}

  constructor(props) {
    super(props)
    this.networkMapRef = React.createRef();
  }
  render() {
    const scenarioControls = scenarios.features.map((feature) => {
      var skey = feature.id;
      var data = require('./scenario-geojson/may6a/isochrone-results/'+skey);
      var result = data.features[0]
      var position = result['geometry']['coordinates'];
      return (
        <LayersControl.Overlay
          key={feature.id}
          name={`${feature.properties.name}: Access from Residences`}
          checked={skey === 'palo-alto' ? true : false}>
          <LayerGroup>
            <Marker position={[position[1], position[0]]}>
              <Popup>
                {originPopupContents(result.properties.results)}
              </Popup>
            </Marker>
            <GeoJSON
              data={data}
              style={isochroneStyleFunction}
              // hide this
              pointToLayer={(feature, latlng) => {
                return null
              }}
              />
          </LayerGroup>
        </LayersControl.Overlay>
      )
    })
    return (
      <React.Fragment>
        <NetworkMap extraLayers={this.extraLayers} ref={this.networkMapRef}>
          <LControl position="topleft" >
            <article className={"message is-warning " + (this.state && this.state.hideMessage ? 'is-hidden': '')}>
              <div className="message-header">
                <h3 className="title is-3">Accessibility Analysis</h3>
                <button class="delete" onClick={() => this.setState({hideMessage: true})}></button>
              </div>
              <div className="message-body">
                <p>This map estimates how access to jobs and housing would improved in 2040 if the <a href="https://www.seamlessbayarea.org/vision-map">Seamless Bay Area Vision Map</a> were implemented, increasing service throughout the region and operating transit as one integrated system.</p>
                <p><strong>Access from Residences</strong>: By default, this map shows how many residents can access jobs in downtown Palo Alto by transit using today's network (<span style={{color: '#D4AF7F', fontWeight: '800'}}>in brown</span>) and using the Seamless network (<span style={{color: '#99d6ff', fontWeight: '800'}}>in blue</span>). Click the checkboxes in the map legend (lower right) to switch to a different regional job or housing center.</p>
                <p><strong>Communities of Concern</strong>: This is a term <a href="https://www.planbayarea.org/2040-plan/plan-details/equity-analysis">defined by MTC</a> to refer to communities that could be considered disadvantaged or vulnerable. To visualize where residents in each CoC can travel, check the box for "MTC Communities of Concern: Access to Jobs" and then click on an orange or red CoC tract.</p>
              </div>
            </article>
          </LControl>
          <LayersControl.Overlay
            key="coc"
            name="MTC Communities of Concern: Access to Jobs"
            checked={false}
            >
            <GeoJSON
              data={cocGeojson}
              style={(feature) => { 
                switch (feature.properties.coc_class) {
                  case 'High':
                    return { fillColor: '#fee8c8', color: '#fee8c8' }
                  case 'Higher':
                    return { fillColor: '#fdbb84', color: '#fdbb84' }
                  case 'Highest':
                    return { fillColor: '#e34a33', color: '#e34a33' }
                  default:
                    return { fillColor: '#eee', color: '#eee' }
                }
              }}
              onEachFeature={(feature, layer) => {
                const map = this.networkMapRef.current
                let tractNumber = feature.properties.tract;
                layer.bindTooltip(`Tract #${tractNumber}, COC Class: ${feature.properties.coc_class}`);
                layer.on('click', (e) => {
                  fetch(`/scenario-geojson/may6a/coc_analysis/${tractNumber}.json`)
                    .then((response) => response.json())
                    .then((json) => {
                      if (this.currentCocFeatureGroup) {
                        map.mapRef.current.leafletElement.removeLayer(this.currentCocFeatureGroup);
                      }
                      let centroidLatLng = centroid(json.features[0].geometry).geometry.coordinates.reverse()

                      let isochrone = L.geoJSON(json, { style: isochroneStyleFunction })
                      let marker = L.marker(centroidLatLng)

                      let popupElement = originPopupContents(json.features[0].properties.results)
                      let popupString = ReactDOMServer.renderToString(popupElement)
                      
                      let featureGroup = L.featureGroup([isochrone, marker])
                      map.mapRef.current.leafletElement.addLayer(featureGroup)
                      marker.bindPopup(popupString).openPopup()
                      this.currentCocFeatureGroup = featureGroup

                      // if user toggles off COC layer, let's clean up
                      map.mapRef.current.leafletElement.on('overlayremove ', (e) => {
                        if (map.mapRef.current) {
                          map.mapRef.current.leafletElement.removeLayer(this.currentCocFeatureGroup)
                        }
                      })
                    })
                })
              }}
            >
            </GeoJSON>
          </LayersControl.Overlay>
          {scenarioControls}
        </NetworkMap>
      </React.Fragment>
    )
  }
}

export default AccessMaps;
